<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="渠道&坑位管理"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="search_item">
                <div>渠道：</div>
                <el-select v-model="searchData.channel" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in channelList" :key="index" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>页面：</div>
                <el-select v-model="searchData.type" placeholder="请选择" clearable>
                  <el-option v-for="(item,index) in pageList" :key="index" :label="item.name" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="search_item">
                <div>坑位名称：</div>
                <el-input v-model="searchData.name"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/management/content/channelPit/add">
            <el-button type="primary">新增子分类</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="渠道" fixed="left" prop="channelStr" :min-width="100"></el-table-column>
      <el-table-column label="坑位页面" prop="typeStr" :min-width="100"></el-table-column>
      <el-table-column label="坑位名称" prop="name" :min-width="120"></el-table-column>
      <el-table-column label="坑位子分类" prop="subType" :min-width="180">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.subType" placement="top">
            <div class="table_row_rule">{{ scope.row.subType }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row, 1)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="图" prop="imageSwitch" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row, 2)"
            v-model="scope.row.imageSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="titleSwitch" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row, 3)"
            v-model="scope.row.titleSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="副标题" prop="subTitleSwitch" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row, 4)"
            v-model="scope.row.subTitleSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime" :min-width="180"></el-table-column>
      <el-table-column label="最近操作人" prop="updateUserName" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="150">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [],
        // 搜索数据
        searchData: {
          channel: "",           //渠道
          type: "",              //页面
          name: '',              //坑位名称
        },
        channelList: [],         //渠道列表
        pageList: [],            //页面列表
        listLoading: false,      //列表loading
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //编辑
          this.$router.push(`/management/content/channelPit/edit?locationNo=${row.locationNo}`);
          break;
        case 2:     //删除
          this.$confirm('是否删除此条数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('management/channelPitDelete',{
              locationNo: row.locationNo
            }).then(res => {
              this.$refs.list.init();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        default:
          break;
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        channel: "",           //渠道
        type: "",              //页面
        name: '',              //坑位名称
      }
    },

    // 更改状态
    changeState(e,row,type){
      this.$store.dispatch('management/channelPitStatus',{
        locationNo: row.locationNo,
        status: e,
        switchType: type
      }).then(res => {
        this.$refs.list.init();
      })
    },

    // 获取channel数据
    getChannelList(){
      this.$store.dispatch('management/getContentChannelList',{}).then(res => {
        this.channelList = res.data;
      })
    },
    // 获取页面数据
    getPageList(){
      this.$store.dispatch('management/getContentPageList',{}).then(res => {
        this.pageList = res.data;
      })
    },
    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/channelPitList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取channel数据
    this.getChannelList();
    // 获取页面数据
    this.getPageList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
